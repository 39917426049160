import { FC, useCallback, useEffect, useState } from 'react';
import authTokenUtil from '../../utils/authToken';
import { getEnvVariable } from '../../utils/getEnvVariable';
import AppItem, { AppItemProps } from './app-item';
import styles from './apps.module.css';

const serverUrl = (): string => getEnvVariable('REACT_APP_SERVER_URL', '')

interface State {
  showResult: boolean
  apps: AppItemProps[]
  error: string | null
}

interface AppsProps {
  user: any
}

const Apps: FC<AppsProps> = ({ user }) => {
  const token = authTokenUtil.getToken()
  const [state, setState] = useState<State>({
    showResult: false,
    apps: [],
    error: null,
  })

  const callApi = useCallback(async () => {
    try {
      if (!token) return

      const response = await fetch(`${serverUrl()}/app_organization_user`, {
        headers: {
          Authorization: token,
        },
      })

      if (response.status !== 200) return setState((prevState) => ({
        ...prevState,
        error: 'no_apps',
      }))

      const responseData = await response.json()

      setState((prevState) => ({
        ...prevState,
        showResult: true,
        apps: responseData,
      }))
    } catch (error: any) {
      setState((prevState) => ({
        ...prevState,
        error: error.error,
      }))
    }
  }, [token])

  useEffect(() => {
    callApi()
  }, [callApi])

  return (
    <>
      {state.error === 'no_apps' && (
        <p color='warning'>
          Er was een probleem met het ophalen van de applicaties. Probeer het later nog eens.
        </p>
      )}

      {state?.apps && (
        <div className={styles.content}>
          <div>
            {state.apps.filter((app) => app.primaryHostname).map((app) => (
              <AppItem key={app.id} {...app} />
            ))}
          </div>
        </div>
      )}
    </>
  )
}

export default Apps
