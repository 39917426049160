import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, Route, Routes } from "react-router-dom";
import styles from './App.module.css';
import { initAuth0 } from './auth0';
import Apps from './components/apps/apps';
import Footer from './components/footer/footer';
import Help from './components/help/help';
import Login from './components/login/Login';
import TopBar from './components/topBar/topBar';
import authTokenUtil from './utils/authToken';

const App = () => {
  // eslint-disable-next-line no-restricted-globals
  const search = new URLSearchParams(location?.search)
  const organization = search?.get('organization') as string

  initAuth0(organization)

  return <RouterComponent/>
}

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const token = authTokenUtil.getToken()

  return token ? children : <Navigate to="/login" />;
};

const RouterComponent = () => {
  const { user } = useAuth0();

  return (
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route 
          path="/" 
          element={
            <ProtectedRoute>
              <main className={styles.main}>
                <TopBar />
                <div className={styles.content}>
                  <div className={styles.welcome}>
                    <p>Welkom {user?.nickname || ''},<br /> dit zijn jouw applicaties</p>
                  </div>
                  <Apps user={user} />
                </div>
                <Help />
                <Footer />
              </main>
            </ProtectedRoute>
          }
        />
      </Routes>
  );
};

export default App;
